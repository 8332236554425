<template>
  <li
      class="accordion__item card-full"
      @click="clickHandler"
  >
    <div class="accordion__header">
      <slot name="accordion-header"/>

      <div
          v-if="showAccordion"
          class="accordion__trigger"
          :class="{ 'active': visible }"
          @click="open"
      >
        <span class="mr-8 semi-bold color--main">{{ visible ? 'Свернуть' : 'Развернуть' }}</span>

        <svgicon
          class="accordion__trigger_icon"
          icon="arrow"
          width="13"
          height="9"
        />
      </div>
    </div>

    <transition
        name="accordion"
        @enter="start"
        @after-enter="end"
        @before-leave="start"
        @after-leave="end">

      <div
        class="accordion__content"
        v-show="visible"
      >
         <div class="pt-30 pb-30">
           <slot name="accordion-content"/>
         </div>
      </div>
    </transition>
  </li>
</template>

<script>
export default {
  props: {
    showAccordion: {
      type: Boolean,
      default: true
    },
    enableClick: {
      type: Boolean,
      default: true
    }
  },
  inject: ['Accordion'],
  data () {
    return {
      index: null
    }
  },

  created () {
    this.index = this.Accordion.count++
  },

  computed: {
    visible () {
      return this.index === this.Accordion.active
    }
  },

  methods: {
    open () {
      if (this.visible) {
        this.Accordion.active = null
      } else {
        this.Accordion.active = this.index
      }
    },
    start (el) {
      el.style.height = el.scrollHeight + 'px'
    },
    end (el) {
      el.style.height = ''
    },

    clickHandler (e) {
      this.$emit('clickHandler', e)
    }
  }
}
</script>

<style lang="scss" scoped>
.accordion__item {
  position: relative;
}

.accordion__header {
  display: flex;
  justify-content: space-between;
  padding: 30px;
}

.accordion__trigger {
  cursor: pointer;
  color: $color__main;

  .accordion__trigger_icon {
    transform: rotate(180deg);
    transition-duration: 0.3s;
  }

  &.active {
    .accordion__trigger_icon {
      transform: rotate(0deg);
      transition-duration: 0.3s;
    }
  }
}

.accordion__content {
  padding: 0 30px;
  border-top: 0.9px solid $color__card-inner-border;
}

.accordion-enter-active,
.accordion-leave-active {
  will-change: height, opacity;
  transition: height 0.3s ease, opacity 0.3s ease;
  overflow: hidden;
}

.accordion-enter,
.accordion-leave-to {
  height: 0 !important;
  opacity: 0;
}

.card-full {
  width: 100%;
  background: var(--color__lavander);
  border-radius: 20px;
  box-shadow: $color__card-box-shadow;
  transition: ease-in-out $transition box-shadow;

  &:hover {
    box-shadow: $color__card-box-shadow--hover;
  }
}

.card-full + .card-full {
  margin-top: 16px;
}
</style>
