<template>
  <div>
    <h1 class="mb-30">Материалы</h1>
    <VInput
        :value="search"
        type="search"
        placeholder="Поиск по названию"
        width="460px"
        class="mb-30"
        @onEnter="loadSearch"
    />

    <div>

      <Spinner v-if="isLoading"/>

      <AccordionList v-if="materialsLength && !isLoading">
        <AccordionItem
          v-for="material in materials"
          :key="material.id"
          :showAccordion="isSubMaterials(material['bars'])"
          :style="!isSubMaterials(material['bars']) ? {cursor: 'pointer'} : ''"
          @clickHandler="!isSubMaterials(material['bars']) && detailInfo(material.id)"
        >
          <template slot="accordion-header">
            <div class="flex gap-l">
              <h4>{{ material.name }}</h4>

              <InfoTextBlock
                v-if="material.vendor_code"
                labelText="Артикул"
                :text="material.vendor_code"
              />
            </div>
          </template>

          <div
            v-if="isSubMaterials(material['bars'])"
            slot="accordion-content"
            class="card-list"
          >
            <div
              v-for="(card, index) in material.bars"
              :key="index"
              class="card-list__item"
              @click="detailInfo(card.id)"
            >
              <h4 class="fw-normal">{{ card.name }}</h4>
              <p>Артикул {{ card.article }}</p>
            </div>
          </div>
        </AccordionItem>
      </AccordionList>
      
      <h2 v-if="!materialsLength && !isLoading">
        Ничего не найдено
      </h2>

      <AppPagination
        v-if="pagination.count > 1"
        :current-page="pagination.page"
        :page-count="pagination.count"
        @setPage="setPage"
      />
    </div>
  </div>
</template>

<script>
import InfoTextBlock from '@/components/ui/AppInfoTextBlock'
import VInput from '@/components/Form/Vinput/VInput'
import AccordionList from '@/components/accordionList/AccordionList'
import AccordionItem from '@/components/accordionList/AccordionItem'
import AppPagination from '@/components/ui/AppPagination'

import { mapMutations, mapActions, mapGetters } from 'vuex'

export default {
  name: 'Warehouse',
  components: { AccordionList, AccordionItem, VInput, InfoTextBlock, AppPagination },
  data () {
    return {
      showStage: true
    }
  },

  mounted () {
    this.getMaterials()
  },

  destroyed () {
    this.RESET_SEARCH()
  },

  computed: {
    ...mapGetters('warehouse', {
      materials: 'material/materials',
      pagination: 'material/pagination',
      search: 'material/search',
      isLoading: 'material/isLoading'
    }),

    materialsLength () {
      return this.materials?.length
    }
  },

  methods: {
    ...mapMutations('warehouse', {
      SET_SEARCH: 'material/SET_SEARCH',
      SET_PAGE: 'material/SET_PAGE',
      RESET_SEARCH: 'material/RESET_SEARCH'
    }),

    ...mapActions('warehouse', {
      getMaterials: 'material/getMaterials'
    }),

    loadSearch (value = '') {
      this.SET_SEARCH(value)
      this.getMaterials({ name: value })
    },
    setPage (num = 1) {
      this.SET_PAGE(num)
      this.getMaterials()
    },

    detailInfo (id) {
      this.$router.push({ name: 'WarehouseMaterialDetailed', params: { id_material: id } })
    },

    isSubMaterials (el) {
      return typeof el !== 'undefined'
    }
  }
}
</script>

<style lang="scss" scoped>
.card-list {
  @include df(flex-start, flex-start, wrap);
  gap: 20px;

  .card-list__item {
    flex-basis: 265px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-size: 16px;
    line-height: 30px;
    text-align: left;
    padding: 15px 20px;
    background: $color__white;
    box-shadow: $color__card-box-shadow;
    border-radius: 20px;
    transition: $transition;

    @media screen and (min-width: $hover-enable) {
      &:hover {
        color: $color__main;
        box-shadow: 0 0 0 1px $color__main;
        cursor: pointer;
      }
    }
  }
}

</style>
